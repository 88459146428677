import React from "react";
import "./Footer.css";
import { FaLocationArrow } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer_green_color">
      <div className="footer_main_box">
        <div className="newsletter">
          <div className="texts">Subscribe to Newsletters</div>
          <div className="mail">
            <div className="email_border">
              <input type="text" placeholder="Your Email" />
              <button className="submitbtn">
                Submit <FaLocationArrow />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
