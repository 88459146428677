import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <div className="background">
      <div className="content-container">
        <h1 className="heading2">
          Look Within Yourself <br /> & Discover
        </h1>
        <hr className="divider" />
        <p className="paragraph">Beauty starts with you</p>
        <button className="know-more-button">Know More</button>
      </div>
    </div>
  );
};

export default Banner;
