import React, { useState } from "react";
import {Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faHome, faCalendarAlt, faProjectDiagram, faEnvelope, faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import "./Header.css"; // You can define your styles in a separate CSS file

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header>
      <div className="container">
        <div className="logo">
          <img src="images/logo.png" alt="Logo" />
        </div>
        {/* <button className="menu-toggle" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faCircleChevronLeft} style={{ color: "white" }} />
        </button> */}
        <div className="tabs">
          <nav className={`menu ${showMenu ? "open" : ""}`}>
            <ul>
              <Link to = "/"> <li><FontAwesomeIcon icon={faHome} /> Home </li> </Link>
              <li><FontAwesomeIcon icon={faCalendarAlt} /> About Us </li>
              <li className="dropdown"> {/* Add dropdown class to the list item */}
                <button className="dropbtn"><FontAwesomeIcon icon={faProjectDiagram} /> Products</button>
                <div className="dropdown-content"> {/* Add dropdown-content for the dropdown options */}
                  <Link to="/face-wash">Face Wash</Link>
                  <Link to="/face-serum">Face Serum</Link>
                </div>
              </li>
              <li><FontAwesomeIcon icon={faEnvelope} /> Contact Us </li>
            </ul>
          </nav>
        </div>
        <div className="search-box">
          <input type="text" placeholder="Search" />
          <button type="submit">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
