import React from 'react';
import Header from '../Header/Header';
import HeroSection from '../HeroSection/HeroSection';
import Banner from '../Banner/Banner';
import AboutUs from '../AboutUs/AboutUs';
import Product from '../Product/Product';
import Contact from '../Contact/Contact';
import Testimonial from '../Testimonial/Testimonial';
import Footer from '../Footer/Footer';
import FooterBottom from '../FooterBottom/FooterBottom';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const HomePage = () => {
  return (
    <>
    <Header/> 
    <HeroSection/> 
    <Banner/> 
    <AboutUs/>
    <Product/>
    <Contact/>
    <Testimonial/>
    <Footer/>
    <FooterBottom/>
    </>
  )
}

export default HomePage