import React from "react";
import { IoLogoFacebook } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";

import './FooterBottom.css';

const FooterBottom = () => {
  return (
    <div>
      <div className="footer_containers">
        <div className="footer_dtls">
          <ul>
            <li> Home </li>
            <li> About Us </li>
            <li> Products </li>
            <li> Contact Us </li>
          </ul>
        </div>

        <div className="icons_grp">
            <div className="logo_1"> <IoLogoFacebook /> </div>
            <div className="logo_1"> <FaInstagramSquare /> </div>
            <div className="logo_1"> <FaTwitterSquare /> </div>
            <div className="logo_1"> <FaYoutubeSquare /> </div>

        </div>
      </div>
        <hr></hr>
        <div className="dtls">
            <div className="texts_center"> © 2024 InterfaceMage. All rights reserved. </div>
            <div className="logo_images"><img src="images/logo.png" alt="" /> </div>
            <div className="links">
                <div className="service"> Terms of Service </div>
                <div className="service"> Privacy Policy </div>
            </div>
        </div>

    </div>
  );
};

export default FooterBottom;
