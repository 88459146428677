import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="left_side">
        <h1 className="main-heading">Need Assistance?</h1>
        <h2 className="sub-heading">We're Here to Help!</h2>
        <img src="images/touch.png" alt="Assistance" className="assistance-image" />
      </div>
      <div className="right_side">
        <form className="contact-form">
          <div className="input-container">
            <input type="text" id="name" name="name" placeholder="" />
            <label htmlFor="name">Your Name</label>
          </div>
          <div className="input-container">
            <input type="email" id="email" name="email" placeholder="" />
            <label htmlFor="email">Your Email</label>
          </div>
          <div className="input-container">
            <input type="tel" id="phone" name="phone" placeholder="" />
            <label htmlFor="phone">Phone No.</label>
          </div>
          <div className="input-container">
            <input type="text" id="reason" name="reason" placeholder="" />
            <label htmlFor="reason">Reason for Contact</label>
          </div>
          <div className="input-container">
            <textarea id="message" name="message" placeholder=""></textarea>
            <label htmlFor="message">Message</label>
          </div>
          <button type="submit" className="connect-button">
            <i className="fa fa-paper-plane"></i> Let's Connect
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
