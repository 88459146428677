import React from 'react';
import './Product.css';

const Product = () => {
  return (
    <div className="product-page-container">
      <h1 className="heading1">Our Featured Products</h1>
      <p className="description">Discover unbeatable offers on top beauty essentials.</p>
      <div className="product-grid">
        <div className="product-box">
          <img src="images/p2.png" alt="Product 1" className="product-image" />
          <h2 className="product-title">Face Serum</h2>
          <button className="know-more-button">Know More</button>
        </div>
        <div className="product-box">
          <img src="images/p2.png" alt="Product 2" className="product-image" />
          <h2 className="product-title">Facial Wash</h2>
          <button className="know-more-button">Know More</button>
        </div>
        <div className="product-box">
          <img src="images/p2.png" alt="Product 3" className="product-image" />
          <h2 className="product-title">Combo**</h2>
          <button className="know-more-button">Know More</button>
        </div>
      </div>
    </div>
  );
};

export default Product;
