import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Testimonial.css';

const Testimonial = () => {
  return (
    <>
      <div className="div_headings" style={{ marginTop: "10px", marginBottom: "0px", color:"#477200" }}> Hear What Others Are Saying ! </div>
      <div className="div_para"> Discover why our customers are loving our products and join the glowing reviews ! </div>

      <Carousel
        showThumbs={false}
        autoPlay
        infiniteLoop
        showStatus={false}
        showArrows
        centerMode
        stopOnHover={false}
      >

        <figure class="snip1390">
          <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample3.jpg" alt="profile-sample3" class="profile" />
          <figcaption>
            <h2>Eleanor Crisp</h2>
            <h6>UX Design</h6>
            <blockquote>Dad buried in landslide! Jubilant throngs fill streets! Stunned father inconsolable - demands recount!</blockquote>
          </figcaption>
        </figure>
        <figure class="snip1390 hover"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample5.jpg" alt="profile-sample5" class="profile" />
          <figcaption>
            <h2>Gordon Norman</h2>
            <h6>Accountant</h6>
            <blockquote>Wormwood : Calvin, how about you? Calvin : Hard to say ma'am. I think my cerebellum has just fused. </blockquote>
          </figcaption>
        </figure>
        <figure class="snip1390 hover"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample5.jpg" alt="profile-sample5" class="profile" />
          <figcaption>
            <h2>Gordon Norman</h2>
            <h6>Accountant</h6>
            <blockquote>Wormwood : Calvin, how about you? Calvin : Hard to say ma'am. I think my cerebellum has just fused. </blockquote>
          </figcaption>
        </figure>

        <figure class="snip1390"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/profile-sample6.jpg" alt="profile-sample6" class="profile" />
          <figcaption>
            <h2>Sue Shei</h2>
            <h6>Public Relations</h6>
            <blockquote>The strength to change what I can, the inability to accept what I can't and the incapacity to tell the difference.</blockquote>
          </figcaption>

        </figure>

        {/* Add more items as needed */}
      </Carousel>
    </>
  );
};

export default Testimonial;
