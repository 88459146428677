import React from 'react';
import './HeroSection.css'; // Import CSS for styling

const HeroSection = () => {
  return (
    <>
        <div className="main_headings"> Welcome to BFH Naturals </div>
   
    <div className="hero-section">
      <div className="hero-content">
        
        <div className="content-left">
          <div className="text-container">
            <div className="main_second_heading"> DRESS YOUR SKIN <br/> WITH   LOVE <br/> AND GLOW </div>
            <p>Our natural facecare products are crafted with  cutting-edge green science  and innovative  ingredients to provide the ultimate luxury skincare experience.</p>
          </div>
          <div className="points-container">
                <div className="features">
                    <div className="features_img"> <img src="images/first.png" alt="" /> </div>
                    <div className="dtl"> Long-lasting and sustainable </div>
                </div>
                <div className="features">
                    <div className="features_img"> <img src="images/second.png" alt="" /> </div>
                    <div className="dtl"> Lovingly crafted for all bodies </div>
                </div>
                <div className="features">
                    <div className="features_img"> <img src="images/third.png" alt="" /> </div>
                    <div className="dtl"> Long-lasting and sustainable </div>
                </div>
          </div>
        </div>
        <div className="image-container"> <img src="images/vector.png" alt="" /> </div>
      </div>

      <div className="points-mobile-container">
                <div className="features">
                    <div className="features_img"> <img src="images/four.png" alt="" /> </div>
                    <div className="dtl" style={{color:"white"}}> Long-lasting and sustainable </div>
                </div>
                <div className="features">
                    <div className="features_img"> <img src="images/five.png" alt="" /> </div>
                    <div className="dtl" style={{color:"white"}}> Lovingly crafted for all bodies </div>
                </div>
                <div className="features">
                    <div className="features_img"> <img src="images/6.png" alt="" /> </div>
                    <div className="dtl" style={{color:"white"}}> Long-lasting and sustainable </div>
                </div>
          </div>

      
    

    </div>
    </>
  );
}

export default HeroSection;
