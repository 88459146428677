import React from 'react';
import { Route,BrowserRouter as Router,Routes } from 'react-router-dom';
// import Header from './components/Header/Header';
// import HeroSection from './components/HeroSection/HeroSection';
// import Banner from './components/Banner/Banner';
// import AboutUs from './components/AboutUs/AboutUs';
// import Product from './components/Product/Product';
// import Contact from './components/Contact/Contact';
// import Testimonial from './components/Testimonial/Testimonial';
// import Footer from './components/Footer/Footer';
// import FooterBottom from './components/FooterBottom/FooterBottom';
import HomePage from './components/pages/HomePage';

// import 'react-responsive-carousel/lib/styles/carousel.min.css';

const App = () => {
  return (
    <>
    <Router>
    <Routes>
         <Route path='/' element={<HomePage/>} />
    </Routes>
    </Router>
    </>
  )
}

export default App